@charset "utf-8";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* padding,border nach innen darstellen */
}


/* type-Selektoren ----------------------*/

html,
body {
    font: 1rem Helvetica, Arial, sans-serif;
    color: #666666;
    /* color: rgb(102, 139, 139); */
    background-color: rgba(255, 255, 255, 0);
}

img {
    max-width: 100%;
}

h1 {
    font-size: 1.5rem;
}

h2 {
    font-size: 1.5rem;
}

li {
    font-size: 1.5rem;
    text-decoration: none;
    list-style-image: url(assets/pics/logo_small_rechts_bullet.png);
}

section {
    padding: 40px 40px;
}


/* Inhalt von oben nach unten -----------*/


/* freie Klassen ------------------------*/

.all {
    max-width: 900px;
    margin: auto;
}

.bgGrey {
    background-color: #666666;
    /* background-color: rgb(102, 139, 139); */
}

.bgWhite {
    background-color: white;
}

.green {
    /* color: #00ff00ff; */
    color: rgba(0, 255, 0, 1);
    /*für Microsoft Browser zwingend in rgba() Schreibweise*/
}

.white {
    color: white;
}

.marBottom {
    margin-bottom: 20px;
}

.bigTypo {
    font-size: 1.5rem;
}

.clearfix:after {
    content: "";
    display: block;
    clear: both;
}